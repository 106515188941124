import { Component, OnInit, HostListener,Inject, OnDestroy, ViewChild, ElementRef} from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Subscription, from } from 'rxjs';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { BaseComponent } from '../../app.base';
import { Constants } from '../../constants/constant';
import { MenuService } from '../../shared/services/menu.service';
import { DataService } from '../../shared/services/dataService';
import { HelperService } from '../../shared/services/helperService';
import { ProfileComponent as ProfileComponentDialogs } from '../../dialogs/user-profile/profile/profile.component';
import { ChangePasswordComponent as ChangePasswordComponentDialogs } from '../../dialogs/change-password/change-password.component';

export interface Options {
	heading?: string;
}

@Component({
	selector: 'app-full-layout',
	templateUrl: './full.component.html',
	styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit, OnDestroy {

	public languages = [
		{ key: 'English', value: 'en' },
		{ key: 'ខ្មែរ', value: 'km' },
	];
	private _router: Subscription;
	shopName : string = "Sub D";
	options: Options;
	menu: Array<any> = [];
	breadcrumbList: Array<any> = [];
	user: any;
	imageURL: string = "../../../../assets/images/users/1.jpg";

	// @ViewChild('fullName') fullName: ElementRef;

	constructor(
		public translate: TranslateService,
		private loadingBar: LoadingBarService,
		private titleService: Title,
		private router: Router,
		private route: ActivatedRoute,
		private menuService: MenuService,
		private dataService: DataService,
		private modalService: NgbModal,
		private helperService: HelperService,
		@Inject(DOCUMENT) private document: Document
	) {
		const browserLang: string = translate.getBrowserLang();
		var reg;
		translate.addLangs(['en', 'km']);
		reg = new RegExp("km|en");
		translate.use(browserLang.match(reg) ? browserLang : 'en');
	}

	ngOnInit() {
		this.titleService.setTitle(this.shopName);
		this.user = JSON.parse(localStorage.getItem(Constants.LOCAL_STORAGE_USER));
		var imageURL = localStorage.getItem(Constants.LOCAL_STORAGE_USER_IMAGE_URL);
		if (imageURL == 'null') {
			this.imageURL = "../../../../assets/images/users/1.jpg";
		} else {
			this.imageURL = imageURL;
		}

		let language = localStorage.getItem(Constants.LOCAL_STORAGE_USER_LOCAL);
		if (language != '') {
			this.translate.use(language);
		}

		this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
			this.route.children.forEach((route: ActivatedRoute) => {
				let activeRoute: ActivatedRoute = route;
				while (activeRoute.firstChild) {
					activeRoute = activeRoute.firstChild;
				}
				this.options = activeRoute.snapshot.data;
			});
			if (this.options.hasOwnProperty('heading')) {
				this.setTitle(this.translate.instant(this.options.heading));
			}
		});

		// option resizes screen;
		var width = (window.innerWidth > 0) ? window.innerWidth : 0;
		var topOffset = 55;
		if (width < 1170) {
			this.document.body.classList.add('mini-sidebar');
			this.document.getElementById('full-name').style.display = 'none';
			this.document.getElementById('short-name').style.display = 'block';
			var element = this.document.getElementsByClassName('sidebartoggler-i');
			element[0].classList.add('ti-menu');
		} else {
			this.document.body.classList.remove("mini-sidebar");
			this.document.getElementById('full-name').style.display = 'block';
			this.document.getElementById('short-name').style.display = 'none';
		}

		var height = ((window.innerHeight > 0) ? window.innerHeight : 0) - 1;
		height = height - topOffset;
		if (height < 1) height = 1;
        if (height > topOffset) {
			this.document.getElementById('page-wrapper-pp').style.minHeight = (height) + "px" ;
		}
	}

	listenRouting() {
		let routerUrl: string, routerList: Array<any>, target: any;
		this.router.events.subscribe((router: any) => {
				routerUrl = router.urlAfterRedirects;
				if (routerUrl && typeof routerUrl === 'string') {
					
					target = this.menu;
					this.breadcrumbList.length = 0;
					routerList = routerUrl.slice(1).split('/');
					routerList.forEach((router, index) => {
						target = target.find(page => page.path.slice(0) === router);
						console.log(router)
						// this.breadcrumbList.push({
						// 	name: target.name,
						// 	path: (index === 0) ? target.path : `${this.breadcrumbList[index-1].path}/${target.path.slice(2)}`
						// });
						// if (index+1 !== routerList.length) {
						// 	target = target.children;
						// }
					});
					// console.log(this.breadcrumbList);
				}
		});
	}

	ngOnDestroy() {
		this._router.unsubscribe();
	}

	onActivate(componentReference) {
		if (componentReference instanceof BaseComponent) {
			componentReference.eventEmitter.subscribe((data) => {
				if(data == true) {
					this.startLoading();
				} else {
					this.stopLoading();
				}
			});
		}
	}

	onTranslate(e) {
		this.translate.use(e);
		let params = {
			"language": e,
			"update_by": this.user.name
		}
		this.dataService.updateUserLanguage(this.user.id, params)
      	.subscribe(
			result => {
				if (result.status == 0) {
					localStorage.setItem(Constants.LOCAL_STORAGE_USER_LOCAL, e);
				}
			},
			error => {
				if (error.statusText === 'Unauthorized') {
					return this.router.navigate(['/signin']);
				}
			}
      	);
	}

	openProfile() {
		const modalRef = this.modalService.open(ProfileComponentDialogs, { centered: true });
		modalRef.componentInstance.passEntryImageURL.subscribe((imageURL) => {
			let isURL = this.helperService.isURL(imageURL);
			if (isURL) {
				localStorage.setItem(Constants.LOCAL_STORAGE_USER_IMAGE_URL, imageURL);
				this.imageURL = imageURL;
			}
		});
	}

	openChangePassword() {
		const modalRef = this.modalService.open(ChangePasswordComponentDialogs, { centered: true });
	}

	startLoading() {
		this.loadingBar.start();
	}
	
	stopLoading() {
		this.loadingBar.complete();
	}

	logout(e) {
		e.stopPropagation();
		localStorage.removeItem(Constants.LOCAL_STORAGE_USER_TOKEN);
		localStorage.removeItem(Constants.LOCAL_STORAGE_USER_TOKEN_EXPIRED_AT);
		localStorage.removeItem(Constants.LOCAL_STORAGE_USER);
		localStorage.removeItem(Constants.LOCAL_STORAGE_USER_PERMISSIONS);
		localStorage.removeItem(Constants.LOCAL_STORAGE_USER_IMAGE_URL);
		localStorage.removeItem(Constants.LOCAL_STORAGE_USER_LOCAL);
		localStorage.removeItem(Constants.LOCAL_STORAGE_ISOWNER);
		localStorage.removeItem(Constants.LOCAL_STORAGE_CURRENCY);
		window.location.reload();
	}

	setTitle(newTitle: string) {
		this.titleService.setTitle(this.shopName + ' | ' + newTitle);
	}
}

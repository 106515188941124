import { Injectable } from '@angular/core';

@Injectable()
export class MenuService {

    constructor() { }

    getMenu(): Array<any> {
        const menu = [
            { name: 'Dashboard', path: '/', children: [] },
            {
                name: 'User',
                path: './user',
                children: [
                    {
                        name: 'List',
                        path: '',
                    },
                    {
                        name: 'New',
                        path: 'new',
                    }
                ]
            },
        ];

        return menu;
    }
}
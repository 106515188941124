import { Component, OnInit } from '@angular/core';
import { Constants } from 'src/app/constants/constant';

@Component({
  selector: 'app-leftsidebar',
  templateUrl: './leftsidebar.component.html',
  styleUrls: ['./leftsidebar.component.css']
})
export class LeftsidebarComponent implements OnInit {

	user: any = null;
	role: string = "";

	ADMIN: string = Constants.ADMIN;
	DEALER: string = Constants.DEALER;
	REPORTER: string = Constants.REPORTER;

	constructor(
	) { }

	ngOnInit() {

		this.user = JSON.parse(localStorage.getItem('user')); 
		if (this.user != null) {
			this.role = this.user.Role;
		}
	}
}

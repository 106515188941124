import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { LoadingComponent } from './loading.component';

@NgModule({
    imports: [],
    exports: [LoadingComponent],
    declarations: [LoadingComponent],
    providers: []
 })
export class LoadingModule { }


import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http'
import { Configuration } from '../../app.config';
import { map, catchError } from 'rxjs/operators';
import { JsonPipe } from '@angular/common';

@Injectable()
export class HttpService {

    constructor(
        private http: HttpClient,
        private configuration: Configuration
    ) { }

    private bearer = localStorage.getItem('app_token');
    private options = {
        headers: new HttpHeaders({ 'Authorization': this.bearer, 'Content-Type': 'application/json' })
    }

    public get(url, param = null): Observable<any> {
        interface Response {
            data: string,
        }
        if (param == null) {
            return this.http.get(this.configuration.apiServerUrl + url, this.options).pipe(
                map((res: Response) => res),
                catchError(this.handleError)
            )
        } else {
            let symbol = '?';
            if (param >= 1) {
                symbol = '/';
            }
            return this.http.get(this.configuration.apiServerUrl + url + symbol + param, this.options).pipe(
                map((res: Response) => res),
                catchError(this.handleError)
            )
        }
    }

    public post(url, body): Observable<any> {
        if (url != 'api/files' && url != 'api/videos') {
            let requestBody = JSON.stringify(body);
        }

        return this.http
                    .post(this.configuration.apiServerUrl + url, body, this.options)
                    .pipe(catchError(this.handleError))
    }

    public postFile(url, fileToUpload: File): Observable<any> {
        let options = { headers: new HttpHeaders({ 'Authorization': this.bearer }) };
        const formData: FormData = new FormData();
        formData.append('file', fileToUpload);
        return this.http
            .post(url, formData, options)
            .pipe(catchError(this.handleError))
    }
    
    public delete(url, param): Observable<any> {
        return this.http
                    .delete(this.configuration.apiServerUrl + url + '/' + param, this.options)
                    .pipe(catchError(this.handleError))
    }

    public put(url, param, body): Observable<any> {
        let requestBody = JSON.stringify(body);

        return this.http.put(this.configuration.apiServerUrl + url + '/' + param, requestBody, this.options)
                .pipe(catchError(this.handleError))
    }

    private handleError(error: any) {
        return observableThrowError(error);
    }
}

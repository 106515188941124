
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Configuration } from '../../app.config';
import 'rxjs/Rx';
import { Constants } from '../../constants/constant';

@Injectable()
export class AuthenticationService {

    constructor(
        private http: HttpClient,
        private configuration: Configuration
    ) { }

    public login(username: string, password: string) {
        let urlLogin = this.configuration.apiServerUrl + "oauth/token";
        let bodyLogin = {
            "grant_type": 'password',
            "client_id": this.configuration.clientId,
            "client_secret": this.configuration.clientSecret,
            "username": username,
            "password": password,
            "scope": ''
        };

        return this.http.post(urlLogin, bodyLogin);
    }

    public sendForgetPasswordEmail(email: string) {
        let url = this.configuration.apiServerUrl + "password/create";
        let body = {
            "email": email
        };

        return this.http.post(url, body);
    }

    public findResetPasswordByToken(token: string) {
        let url = this.configuration.apiServerUrl + "password/find/" + token;
        return this.http.get(url);
    }

    public sendResetPasswordWithToken(email, token: string, new_password: string, confirm_new_password: string) {
        let url = this.configuration.apiServerUrl + "password/reset";
        let body = {
            "email": email,
            "password": new_password,
            "password_confirmation": confirm_new_password,
            "token": token
        };

        return this.http.post(url, body);
    }

    public isAuthenticated(): boolean {
        const token = localStorage.getItem(Constants.LOCAL_STORAGE_USER_TOKEN);
        const tokenExpired = localStorage.getItem(Constants.LOCAL_STORAGE_USER_TOKEN_EXPIRED_AT);
        const nowInMilliseconds = new Date().getTime();

        // if no token or token expired return false
        if (token == null || nowInMilliseconds > parseInt(tokenExpired)) {
            return false;
        }

        return true;
    }

    private hearderlogin(){
        let headers = new HttpHeaders({ 
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        });
        let options = { 
            headers: headers,
            crossDomain: true
        };
        return options
    }
}

import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { Observable, TimeoutError } from 'rxjs';
import { Constants } from 'src/app/constants/constant';

@Injectable()
export class RoleGuardService implements CanActivate{

    private user : any = [];
    constructor(private _authService: AuthenticationService, private _router: Router) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        
        this.user = JSON.parse(localStorage.getItem('user'));
        
        if (this.user != null) {
            if (this.user.Role == Constants.DEALER && next.data.menu == Constants.USER) {
                this._router.navigate(['/error/404']);
                return false;
            }

            if (this.user.Role == Constants.REPORTER && 
                (next.data.menu != Constants.STOCK_REPORT && 
                    next.data.menu != Constants.SALE_REPORT && 
                        next.data.menu != Constants.DASHBOARD)) {
                this._router.navigate(['/error/404']);
                return false;
            }
        }
        return true;
    }
}
export class Constants {
    public static LOCAL_STORAGE_USER = "user";
    public static LOCAL_STORAGE_USER_PERMISSIONS = "role";
    public static LOCAL_STORAGE_USER_TOKEN = "app_token";
    public static LOCAL_STORAGE_USER_TOKEN_EXPIRED_AT = "expires_at";
    public static LOCAL_STORAGE_CLIENT = "client";
    public static LOCAL_STORAGE_USER_IMAGE_URL = "user_image";
    public static LOCAL_STORAGE_USER_LOCAL = "user_local";
    public static LOCAL_STORAGE_CURRENCY = "currency";
    public static LOCAL_STORAGE_ISOWNER="isowner";
    public static LOCAL_STORAGE_VATTIN="vattin";

    // Role
    public static ADMIN="administrator";
    public static DEALER="dealer";
    public static REPORTER="reporter";


    // Menu
    public static DASHBOARD="Dashboard";
    
    public static PRODUCT="Product";
    public static WAREHOUSE="Warehouse";
    public static SALE_MAN="SaleMan";
    public static CUSTOMER="Customer";

    public static RECEIVE_STOCK="Receive_Stock";
    public static TRANSFER="Transfer";
    public static ADJUSTMENT="Adjustment";

    public static SALE_ORDER="Sale_Order";
    public static VAN_SALE="Van_Sale";

    public static STOCK_REPORT="Stock_Report";
    public static SALE_REPORT="Sale_Report";

    public static USER="User";
}
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { DataService } from '../../shared/services/dataService';
import { Constants } from '../../constants/constant';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

	changePassword: any = {};

	constructor(
		private activeModal: NgbActiveModal,
		private dataService: DataService,
		private router: Router,
		public translate: TranslateService
	) {}

	ngOnInit() {
		this.changePassword = {
			old_pass: '',
			new_pass: '',
			c_password: ''
		}
	}

	onSave(form) {
		this.dataService.changeUserPassword(this.changePassword)
      	.subscribe(
			result => {
				if (result.status == 0) {
					this.activeModal.close('Modal Closed');
					Swal.fire({
						icon: 'success',
						title: this.translate.instant('alert.success'),
						text: '',
						showConfirmButton: false,
						timer: 1500
					})
				} else {
					Swal.fire({
						icon: 'error',
						title: 'Error',
						text: result.message,
						showConfirmButton: false,
						timer: 1500
					});
				}
			},
			error => {
				if (error.statusText === 'Unauthorized') {
					return this.router.navigate(['/signin']);
				}
			}
      	);
	}
	
	closeModal() {
		this.activeModal.close('Modal Closed');
	}

}

import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
// import { INgxMyDpOptions, IMyDateModel } from 'ngx-mydatepicker';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import Swal from 'sweetalert2'
import { Constants } from '../../constants/constant';

@Injectable()
export class HelperService {
  // datePickerOptions: INgxMyDpOptions = {
  //   dateFormat: 'yyyy/mm/dd',
  // };

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private translateService: TranslateService
  ) { }

  handleErrorResponse(error) {
    if (error.statusText === 'Unauthorized') {
      return this.router.navigate(['/signin']);
    }

    let messageError = JSON.parse(error._body);
    return this.toastr.error(
      messageError.message,
      error.statusText
    );
  }

  declareCheckBoxSelectionModule(allModules) {
    let module = {};
    let moduleArray = [];
    for (let i in allModules) {
      module = {
        id: allModules[i].id,
        itemName: allModules[i].name
      }
      moduleArray.push(module);
    }

    return moduleArray;
  }

  getCurrentDate() {
    let today = new Date();
    return today.getFullYear() + '/' + today.getMonth() + '/' + today.getDate() + ' 00:00';
  }

  /*
  *  PreferKey Array :key to keep
  *  ObjectArr Array Object :object to sort out the key
  */
  newObjectArrByPreferKey(preferKey, objectArr) {
    for (let i in objectArr) {
      Object.keys(objectArr[i]).forEach(function (key) {
        if (preferKey.indexOf(key) > -1) {
        } else {
          delete objectArr[i][key];
        }
      });
    }

    return objectArr;
  }

  translateArr(arr) {

    var data = [];
    for(var i = 0; i < arr.length; i++) {
      var text = {};
      if(arr[i].class != undefined) {
        text = this.translateService.instant(arr[i].title);
      } else {
        text = this.translateService.instant(arr[i]);
      }
      i == 0 ? data = [text] : data.push(text);
    }

    return data;
  }

  /**
   * @param locale current locale en|km|zh
   */
  // getTranslateDatepicker(locale: string) {
  //   if (locale == 'km') {
  //     return this.datePickerOptions = {
  //       dateFormat: 'yyyy/mm/dd',
  //       dayLabels: { su: 'អាទិត្យ', mo: 'ចន្ទ', tu: 'អង្គារ', we: 'ពុធ', th: 'ព្រហ', fr: 'សុក្រ', sa: 'សៅរ៍' },
  //       monthLabels: { 1: 'មករា', 2: 'កុម្ភៈ', 3: 'មីនា', 4: 'មេសា', 5: 'ឧសភា', 6: 'ឧសភា', 7: 'មិថុនា', 8: 'សីហា', 9: 'កញ្ញា', 10: 'តុលា', 11: 'វិច្ឆិកា', 12: 'ធ្នូ' },
  //       todayBtnTxt: 'ថ្ងៃនេះ'
  //     };
  //   }
  //   if (locale == 'zh') {
  //     return this.datePickerOptions = {
  //       dateFormat: 'yyyy/mm/dd',
  //       dayLabels: { su: '星期天', mo: '星期一', tu: '星期二', we: '星期三', th: '星期四', fr: '星期五', sa: '星期六' },
  //       monthLabels: { 1: '一月', 2: '二月', 3: '三月', 4: '四月', 5: '五月', 6: '六月', 7: '七月', 8: '八月', 9: '九月', 10: '十月', 11: '十一月', 12: '十二月' },
  //       todayBtnTxt: '今天'
  //     };
  //   }

  //   return this.datePickerOptions = {
  //     dateFormat: 'yyyy/mm/dd',
  //     dayLabels: { su: 'Sun', mo: 'Mon', tu: 'Tue', we: 'Wed', th: 'Thu', fr: 'Fri', sa: 'Sat' },
  //     monthLabels: { 1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec' },
  //     todayBtnTxt: 'Today'
  //   };
  // }

  /**
   * Get alert meesage on delete school by language
   *
   * @param type sweet alert type success|error
   * @param locale current locale en|km|zh
   */
  getTranslateAlertResult(type: string, title: string) {
    // Success alert
    if (type == 'success') {
      return Swal.fire({
        icon: 'success',
        title: title,
        showConfirmButton: false,
        timer: 1500
      }).then(
        function () { },
        function (dismiss) {
          if (dismiss === 'timer') {
          }
        }
      )
    } else {
      return Swal.fire({
        icon: 'error',
        text: title,
        width: 370,
        showConfirmButton: false,
        timer: 15000
      }).then(
        function () { },
        function (dismiss) {
          if (dismiss === 'timer') { }
        }
      )
    }
  }

  /**
   * @param onConfirm
   * @param onCancel
   */
  dialogsSwalConfirmStatus (onConfirm, onCancel) {
    Swal.fire({
      title: this.translateService.instant('alert.change.status.title'),
      text: '',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: this.translateService.instant('alert.btn.cancel'),
      confirmButtonText: this.translateService.instant('alert.btn.ok')
    }).then(onConfirm, onCancel);
  }

  /**
   * @param onConfirm
   * @param onCancel
   */
  dialogConfirm (message, onConfirm, onCancel) {
    Swal.fire({
      title: this.translateService.instant(message),
      text: '',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: this.translateService.instant('alert.btn.cancel'),
      confirmButtonText: this.translateService.instant('alert.btn.ok')
    }).then(onConfirm, onCancel);
  }

  /**
   *
   * @param type sweet alert type success|error
   * @param title
   * @param text
   */
  getTranslateAlertSweetResult(type: string, title: string, text: string) {

    if (type == 'success') {
      return Swal.fire({
        icon: 'success',
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 1500
      }).then(
        function () { },
        function (dismiss) {
          if (dismiss === 'timer') { }
        }
      )
    }

    if (type == 'error') {
      return Swal.fire({
        icon: 'error',
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 1500
      }).then(
        function () { },
        function (dismiss) {
          if (dismiss === 'timer') { }
        }
      )
    }

    if (type == "warning") {
      return Swal.fire({
        icon: 'warning',
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 1500
      }).then(
        function () { },
        function (dismiss) {
          if (dismiss === 'timer') { }
        }
      )
    }

    if (type == "info") {
      return Swal.fire({
        icon: 'info',
        title: '',
        text: text,
        showConfirmButton: false,
        timer: 1500
      }).then(
        function () { },
        function (dismiss) {
          if (dismiss === 'timer') { }
        }
      )
    }
  }

  /**
   *
   * @param type sweet alert type success|error
   * @param title
   * @param text
   */
  getTranslateAlertSweetResultNotTimmer(type: string, title: string, text: string) {

    if (type == 'success') {
      return Swal.fire({
        icon: 'success',
        title: title,
        text: text,
        showConfirmButton: false,
      }).then(
        function () { },
        function (dismiss) {
          if (dismiss === 'timer') { }
        }
      )
    }

    if (type == 'error') {
      return Swal.fire({
        icon: 'error',
        title: title,
        text: text,
        showConfirmButton: false
      }).then(
        function () { },
        function (dismiss) {
          if (dismiss === 'timer') { }
        }
      )
    }

    if (type == "warning") {
      return Swal.fire({
        icon: 'warning',
        title: title,
        text: text,
        showConfirmButton: false
      }).then(
        function () { },
        function (dismiss) {
          if (dismiss === 'timer') { }
        }
      )
    }

    if (type == "info") {
      return Swal.fire({
        icon: 'info',
        title: '',
        text: text,
        showConfirmButton: false
      }).then(
        function () { },
        function (dismiss) {
          if (dismiss === 'timer') { }
        }
      )
    }
  }

  prepareTitleTranslation(datas) {
		if(datas == null || datas.length == 0) {
			return;
		}
		datas.forEach((elem) => {
			elem.title = elem.title_en;
			switch (this.translateService.currentLang) {
				case "zh":
					if (elem.title_cn != "" && elem.title_cn != null) {
						elem.title = elem.title_cn;
					}
					break;
				case "km":
					if (elem.title_kh != "" && elem.title_kh != null) {
						elem.title = elem.title_kh;
					}
					break;

			}
		});
  }

  getTranslateSelect2Title(result) {
    var data = [];
    for(var i = 0; i < result.length; i++) {
      var text = result[i].title_en;
			switch (this.translateService.currentLang) {
				case "zh":
					if (result[i].title_cn != "" && result[i].title_cn != null) {
						text = result[i].title_cn;
					}
					break;
				case "km":
					if (result[i].title_kh != "" && result[i].title_kh != null) {
						text = result[i].title_kh;
					}
					break;
      }
      var obj = {
        id: result[i].id,
        text: text
      };
      i == 0 ? data = [obj] : data.push(obj);
    }

    return data;
  }

  getTranslateSelect2BusinessName(result) {
    var data = [];
    for(var i = 0; i < result.length; i++) {
      var text = result[i].business_name_en;
			switch (this.translateService.currentLang) {
				case "zh":
					if (result[i].business_name_cn != "" && result[i].business_name_cn != null) {
						text = result[i].business_name_cn;
					}
					break;
				case "km":
					if (result[i].business_name_kh != "" && result[i].business_name_kh != null) {
						text = result[i].business_name_kh;
					}
					break;
      }
      var obj = {
        id: result[i].id,
        text: text
      };
      i == 0 ? data = [obj] : data.push(obj);
    }

    return data;
  }

  sortTitleSelection2(result) {
    var data = result.sort(function(a, b) {
      var textA = a.text.toUpperCase();
      var textB = b.text.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });

    return data;
  }

  getItemsPerPageList() {
    return [
      {
        value: 10
      },
      {
        value: 20
      },
      {
        value: 50
      },
      {
        value: 100
      },
      {
        value: 200
      }
    ];
  }

  hasAction(menu) {
    let permissions =  JSON.parse(localStorage.getItem(Constants.LOCAL_STORAGE_USER_PERMISSIONS));
    let permissionObj = permissions.find(obj => obj.module == menu);
		if(permissionObj != null) {
      return permissionObj.permission > 1;
    }

		return false;
  }
  
  isURL(str) {
		var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
		'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
		'((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
		'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
		'(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
		'(\\#[-a-z\\d_]*)?$','i'); // fragment locator
		return pattern.test(str);
	}
}

import { Injectable } from '@angular/core';
import { HttpService } from './httpService'
import { TranslateService } from '@ngx-translate/core';
import { HttpParams, HttpHeaders } from '@angular/common/http';

@Injectable()
export class DataService {
	constructor(
		private http: HttpService,
		private translate: TranslateService,
	) { }
	
	// Api for user management
	createUser(body) {
		return this.http.post('user-management/create_user', body);
	}
	editUser(param, body) {
		return this.http.put('user-management/update_user', param, body);
	}
	getAllUsers(param) {
		return this.http.get('user-management/user_list', param.toString());
	}
	getUser(param) {
		return this.http.get('user-management/view_user', param);
	}
	delectUser(param, body) {
		return this.http.put('user-management/delete_user', param, body);
	}
	resetPasswordUser(body) {
		return this.http.post('user-management/resetpassword', body);
	}
	updateUserLanguage(param, body) {
		return this.http.put('user-management/change_language', param, body);
	}
	uploadUserProfile(param, body) {
		return this.http.put('update-image/image', param, body);
	}
	changeUserPassword(body) {
		return this.http.post('user-management/change_pwd', body);
	}

	// Location
	getLocations(param) {
		return this.http.get('master-data/location_list', param.toString());
	}
	getLocation(param) {
		return this.http.get('master-data/view_location', param);
	}
	createLocation(body) {
		return this.http.post('master-data/create_location', body);
	}
	updateLocation(param, body) {
		return this.http.put('master-data/update_location', param, body);
	}
	delectLocation(param) {
		return this.http.delete('master-data/delete_location', param);
	}
	getLocationActive() {
		return this.http.get('master-data/select_location');
	}

	// Customer
	getCustomers(param) {
		return this.http.get('master-data/customer_list', param.toString());
	}
	getSelectCustomers() {
		return this.http.get('master-data/select_customer');
	}
	delectCustomer(param) {
		return this.http.delete('master-data/delete_customer', param);
	}
	createCutomer(body) {
		return this.http.post('master-data/create_customer', body);
	}
	viewCustomer(param) {
		return this.http.get('master-data/view_customer', param.toString());
	}
	updateCustomer(param, body) {
		return this.http.put('master-data/update_customer', param, body);
	}

	// get Country
	getCountrys(param){
		return this.http.get('master-data/country_list', param.toString());
	}

	// get divisions, get categorys, get brands
	getDCB(params) {
		return this.http.get('master-data/product_filter', params.toString());
	}

	// products
	getProducts(params) {
		return this.http.get('master-data/product_list', params.toString());
	}
	getViewProduct(param) {
		return this.http.get('master-data/product_view', param);
	}

	//  sale man
	createSaleman(body) {
		return this.http.post('master-data/create_salesman', body);
	}
	updateSaleman(param, body) {
		return this.http.put('master-data/update_salesman', param, body);
	}
	getSaleman(param){
		return this.http.get('master-data/view_salesman', param.toString());
	}
	delectSaleman(param) {
		return this.http.delete('master-data/delete_salesman', param);
	}
	getSalemans(param){
		return this.http.get('master-data/salesman_list', param.toString());
	}

	// PO
	getPurchaseOrder(param){
		return this.http.get('po/po_list', param.toString());
	}
	createPurchaseOrder(body) {
		return this.http.post('po/create_po', body);
	}
	getViewPurchaseOrder(param){
		return this.http.get('po/po_view', param.toString());
	}
	updatePurchaseOrder(param, body) {
		return this.http.put('po/update_po', param, body);
	}
	delectPurchaseOrder(param) {
		return this.http.delete('po/void_po', param);
	}

	// transfer
	getTransfers(param) {
		return this.http.get('transfer/transfer_list', param.toString());
	}
	createTransfer(body) {
		return this.http.post('transfer/create_transfer', body);
	}
	updateTransfer(param, body) {
		return this.http.put('transfer/update_transfer', param, body);
	}
	getViewTransfer(param) {
		return this.http.get('transfer/transfer_view', param);
	}
	delectTransfer(param) {
		return this.http.delete('transfer/void_transfer', param);
	}

	// get divisions
	getDivisions() {
		return this.http.get('master-data/division');
	}
	getAllDivisions() {
		return this.http.get('master-data/alldivision');
	}

	// Adjuctment
	AdjustmentLogin(body){
		return this.http.post('user-management/adjustmentlogin', body);
	}
	createAdjuctment(body) {
		return this.http.post('adjustment/create_adjustment', body);
	}
	getAdjuctments(param) {
		return this.http.get('adjustment/adjustment_list', param.toString());
	}

	// sale order
	createSaleOrder(body) {
		return this.http.post('sale/create_sale', body);
	}
	getSaleOrders(param) {
		return this.http.get('sale/sale_list', param.toString());
	}
	getViewSaleOrder(param) {
		return this.http.get('sale/sale_view', param);
	}
	updateSaleOrder(param, body) {
		return this.http.put('sale/update_sale', param, body);
	}
	deleteSaleOrder(param) {
		return this.http.delete('sale/void_sale', param);
	}

	// get sale, location, customer
	getSLC(param) {
		return this.http.get('master-data/select_data_sale', param.toString());
	}

	// get client and Principle
	getClientsAndPrinciples(param) {
		return this.http.get('user-management/selectclientandprinciple', param.toString());
	}

	// dashboard
	getSaleInMonthLater(param) {
		return this.http.get('dashboard/saleinamonthlater', param.toString());
	}
	getInventoryOnLocation(param) {
		return this.http.get('dashboard/inventoryonlocation', param.toString());
	}
	getCustomerTop(param) {
		return this.http.get('dashboard/customertop', param.toString());
	}

	// report stock
	getReportInventoryHistory(param) {
		return this.http.get('report/inventoryhistory', param.toString());
	}
	getReportInventoryDetail(param) {
		return this.http.get('report/inventorydetail', param.toString());
	}

	// report sale
	getReportSaleInvoiceDetail(param) {
		return this.http.get('report/saleinvoicedetail', param.toString());
	}
	getReportSaleSummaryGroupByDate(param) {
		return this.http.get('report/salesummarygroupbydate', param.toString());
	}
	getReportSaleSummaryGroupBySaleMan(param) {
		return this.http.get('report/salesummarygroupbysaleman', param.toString());
	}
	getReportSaleSummaryGroupbyCustomer(param) {
		return this.http.get('report/salesummarygroupbycustomer', param.toString());
	}

	getSalePrinting(param) {
		return this.http.get('report/saleprinting', param);
	}
	getTransferPrint(param) {
		return this.http.get('report/transferprinting', param);
	}

	// getClient
	getClients(param) {
		return this.http.get('master-data/select_client', param.toString());
	}

	getEndingStock(param) {
		return this.http.get('report/endingstock', param);
	}

	getProductsVanSale(body) {
		return this.http.post('vansale/productendingstock_list', body);
	}

	getVanSallers(param) {
		return this.http.get('vansale/getvansale?SellerNo='+param);
	}

	getVanSaleOrder(param) {
		return this.http.get('vansale/vansale_view', param);
	}
	// vansale order
	createVanSaleOrder(body) {
		return this.http.post('vansale/create_vansale', body);
	}
	updateVanSaleOrder(param, body) {
		return this.http.put('vansale/update_vansale', param, body);
	}
	deleteVanSaleOrder(param) {
		return this.http.delete('vansale/void_vansale', param);
	}
}
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  CommonModule,
  LocationStrategy,
  PathLocationStrategy
} from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthGuardService } from './shared/services/auth-guard.service';
import { RoleGuardService } from './shared/services/role-guard.service';
import { DataService } from './shared/services/dataService';
import { Configuration } from './app.config';
import { HttpService } from './shared/services/httpService';
import { HelperService } from './shared/services/helperService';
import { AuthenticationService } from './shared/services/authentication.service';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { FullComponent } from './layouts/full/full.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { LeftsidebarComponent } from './layouts/full/components/leftsidebar/leftsidebar.component';
import { BaseComponent } from './app.base';
import { MenuService } from './shared/services/menu.service';
import { ProfileComponentModule as ProfileComponentDialogModule  } from './dialogs/user-profile/profile/profile.module';
import { ChangePasswordModule } from './dialogs/change-password/change-password.module';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 1,
  wheelPropagation: true,
  minScrollbarLength: 20
};

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
	declarations: [
		AppComponent,
		AuthLayoutComponent,
		FullComponent,
		LeftsidebarComponent,
		BaseComponent
	],
	imports: [
		CommonModule,
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		HttpClientModule,
		PerfectScrollbarModule,
		ProfileComponentDialogModule,
		ChangePasswordModule,
		RouterModule.forRoot(AppRoutes),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (createTranslateLoader),
				deps: [HttpClient]
			}
		}),
		ToastrModule.forRoot({
			timeOut: 2500,
			positionClass: 'toast-top-right',
			preventDuplicates: true
		}),
		NgbModule.forRoot(),
		LoadingBarRouterModule,
		LoadingBarModule
	],
	providers: [
		AuthGuardService,
		RoleGuardService,
		DataService,
		Configuration,
		HttpService,
		AuthenticationService,
		MenuService,
		HelperService,
		CookieService,
		{
			provide: LocationStrategy,
			useClass: PathLocationStrategy
		},
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
		}
	],
	exports: [RouterModule],
	bootstrap: [AppComponent]
})
export class AppModule {}

@NgModule({
	exports: [
		TranslateModule
	]
})
export class SharedModule { }

import { Component, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-base',
    template: ''
})
export class BaseComponent {
    @Output() eventEmitter: EventEmitter<any> = new EventEmitter();

    showLoading() {
        this.eventEmitter.emit(true);
    }

    hideLoading() {
        this.eventEmitter.emit(false);
    }
}
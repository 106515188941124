import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { DataService } from '../../../shared/services/dataService';
import { Constants } from '../../../constants/constant';

@Component({
	selector: 'app-dialogs-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

	user: any;
	imageURL: string = "../../../../assets/images/users/1.jpg";
	loading: boolean = false;
	@Output() passEntryImageURL: EventEmitter<any> = new EventEmitter();

	constructor(
		private activeModal: NgbActiveModal,
		private dataService: DataService,
		private router: Router
	) {}

	ngOnInit() {
		this.user = JSON.parse(localStorage.getItem(Constants.LOCAL_STORAGE_USER));
		this.loading = true;
		this.dataService.getUser(this.user.id)
		  	.subscribe(
				result => {
					this.loading = false;
					this.user = result.data;
					if (result.data.imageURL!=null) {
						this.imageURL = result.data.imageURL;
					}
				},
				error => {
					this.loading = false;
					if (error.statusText === 'Unauthorized') {
						return this.router.navigate(['/signin']);
					}
				}
		);
	}

	closeModal() {
		this.activeModal.close('Modal Closed');
		this.passEntryImageURL.emit(this.imageURL);
	}

	uploadProfile(event) {
		if (event.target.files && event.target.files[0]) {
			var reader = new FileReader();
			reader.readAsDataURL(event.target.files[0]);
			reader.onload = (e: any) => {
				let params = {
					"type": "user",
					"image": e.target.result
				}
				this.loading = true;
				this.dataService.uploadUserProfile(this.user.id, params)
					.subscribe(
						result => {
							this.loading = false;
							if (result.status == 0 ) {
								this.imageURL = result.imageURL;
								localStorage.setItem(Constants.LOCAL_STORAGE_USER_IMAGE_URL, this.imageURL);
							}
						},
						error => {
							if (error.statusText === 'Unauthorized') {
								return this.router.navigate(['/signin']);
							}
						}
					);
				}
			}
	}
}

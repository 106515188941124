import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AuthGuardService as AuthGuard } from './shared/services/auth-guard.service';
import { RoleGuardService as RoleGuard } from './shared/services/role-guard.service';
import { Constants } from 'src/app/constants/constant';

export const AppRoutes: Routes = [
	{
		path: '',
		canActivate: [AuthGuard],
		component: FullComponent,
		children: [
			{
				path: 'dashboard',
				loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
				canActivate: [AuthGuard, RoleGuard],
				data: {menu: Constants.DASHBOARD}
			},
			{
				path: 'user',
				loadChildren: () => import('./user/user.module').then(m => m.UserModule),
				canActivate: [AuthGuard, RoleGuard],
				data: {menu: Constants.USER}
			},
			{
				path: 'master-data',
				children: [
					{
						path: 'product',
						loadChildren: () => import('./master-data/item/item.module').then(m => m.ItemModule),
						canActivate: [AuthGuard, RoleGuard],
						data: {menu: Constants.PRODUCT}
					},
					{
						path: 'location',
						loadChildren: () => import('./master-data/location/location.module').then(m => m.LocationModule),
						canActivate: [AuthGuard, RoleGuard],
						data: {menu: Constants.WAREHOUSE}
					},
					{
						path: 'customer',
						loadChildren: () => import('./master-data/customer/customer.module').then(m => m.CustomerModule),
						canActivate: [AuthGuard, RoleGuard],
						data: {menu: Constants.CUSTOMER}
					},
					{
						path: 'saleman',
						loadChildren: () => import('./master-data/saleman/saleman.module').then(m => m.SalemanModule),
						canActivate: [AuthGuard, RoleGuard],
						data: {menu: Constants.SALE_MAN}
					}
				]
			},
			{
				path: 'stock',
				children: [
					{
						path: 'purchase-order',
						loadChildren: () => import('./stock/po/purchaseOrder.module').then(m => m.PurchaseOrderModule),
						canActivate: [AuthGuard, RoleGuard],
						data: {menu: Constants.RECEIVE_STOCK}
					},
					{
						path: 'transfer',
						loadChildren: () => import('./stock/transfer/Transfer.module').then(m => m.TransferModule),
						canActivate: [AuthGuard, RoleGuard],
						data: {menu: Constants.TRANSFER}
					},
					{
						path: 'adjustment',
						data : {isowner : 'isowner', menu: Constants.ADJUSTMENT},
						loadChildren: () => import('./stock/adjustment/adjustment.module').then(m => m.AdjustmentModule),
						canActivate: [AuthGuard, RoleGuard]
					}
				]
			},
			{
				path: 'saleorder',
				loadChildren: () => import('./saleOrder/saleOrder.module').then(m => m.saleOrderModule),
				canActivate: [AuthGuard, RoleGuard],
				data: {menu: Constants.SALE_ORDER}
			},
			{
				path: 'van-sale',
				loadChildren: () => import('./vanSale/vanSale.module').then(m => m.vanSaleModule),
				canActivate: [AuthGuard, RoleGuard],
				data: {menu: Constants.VAN_SALE}
			},
			{
				path: 'report',
				children: [
					{
						path: 'sale',
						loadChildren: () => import('./report/sale/saleReport.module').then(m => m.SaleReportModule),
						canActivate: [AuthGuard, RoleGuard],
						data: {menu: Constants.SALE_REPORT}
					},
					{
						path: 'stock',
						loadChildren: () => import('./report/stock/stockReport.module').then(m => m.StockReportModule),
						canActivate: [AuthGuard, RoleGuard],
						data: {menu: Constants.STOCK_REPORT}
					}
				]
			},
		]
	},
	{
		path: '',
		component: AuthLayoutComponent,
		children: [
			{
				path: 'signin',
				loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
			}
		]
	},
	{
		path: 'error',
		loadChildren: () => import('./error/error.module').then(m => m.ErrorModule)
	},
	{
		path: '**',
		redirectTo: 'error/404'
	}
];

import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { Constants } from 'src/app/constants/constant';

@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(
        public auth: AuthenticationService,
        public router: Router
    ) {}

    canActivate(): boolean {
        if (!this.auth.isAuthenticated()) {
                this.router.navigate(['/signin']);
            return false;
        }
        localStorage.setItem(Constants.LOCAL_STORAGE_ISOWNER, "false");
        return true;
    }
}
